import NetworkManager from './NetworkManager';

export const PionusDocument = Object.freeze({
    image: { identifier: "image.generic", extension: undefined, dataType: "text" },
    png: { identifier: "image.png", "extension": ".png", dataType: "text" },
    jpg: { identifier: "image.jpg", "extension": ".jpg", dataType: "text" },
    pdf: { identifier: "pdf", extension: ".pdf", dataType: "text" },
    html: { identifier: "html", extension: ".html", dataType: "html" },
    raw: { identifier: "raw", extension: undefined, dataType: "text" }
});

export class DocumentNotFoundException extends Error {
    constructor() {
        super("Document not found");
    }
}

export class PionusDocumentCache {
    static DocumentNotFoundException = new DocumentNotFoundException();

    //static _DOCUMENT_CACHE_STORAGE_KEY = "vbf.documentCache";

    static _shared = null;
    static get shared() {
        if (PionusDocumentCache._shared == null) {
            PionusDocumentCache._shared = new PionusDocumentCache();
        }

        return this._shared;
    }

    constructor() {
        this._documentData = {};
        this._documentRequests = {};
    }

    set(identifier, data) {
        this._documentData[identifier] = data;

        // The reason we don't do this yet is because we do not refresh cached items yet when their data has changed
        // In the future, we could resolve a cached result first whilst simultaneously calling the API to check whether the file has changed
        // If it has, we can just re-resolve the file to update any outdated documents that may have been shown

        //localStorage.setItem(PionusDocumentCache._DOCUMENT_CACHE_STORAGE_KEY, JSON.stringify(this._documentData));
    }

    _resolveDocument(identifier, data) {
        this.set(identifier, data);

        for (let request of this._documentRequests[identifier]) {
            request.resolve(data);
        }

        delete this._documentRequests[identifier];
    }

    _rejectDocument(identifier, reason) {
        for (let request of this._documentRequests[identifier]) {
            request.reject(reason);
        }

        delete this._documentRequests[identifier];
    }

    _prepareDocumentRequest(identifier) {
        let resolve = null, reject = null;
        let promise = new Promise((res, rej) => { resolve = res; reject = rej; });
        let returnEarly = false;

        if (this._documentRequests[identifier] != null) {
            this._documentRequests[identifier].push({ resolve: resolve, reject: reject });
            returnEarly = true;
        } else {
            this._documentRequests[identifier] = [{ resolve: resolve, reject: reject }];
        }

        return { promise: promise, returnEarly: returnEarly };
    }

    async getBlob(documentId, documentType, blobOpts, directLink = null) {
        let blobId = `${documentId}.${documentType.identifier}.blob`;
        if (this._documentData[blobId] != null) {
            return this._documentData[blobId];
        }

        let documentRequest = this._prepareDocumentRequest(blobId);
        if (documentRequest.returnEarly) {
            return documentRequest.promise;
        }

        this.get(documentId, documentType, true, directLink).then(data => {
            let binaryData = null;

            try {
                binaryData = atob(data);
            } catch(e) {
                this._rejectDocument(blobId, e);
                return;
            }

            let byteArray = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                byteArray[i] = binaryData.charCodeAt(i);
            }

            let blobUrl = URL.createObjectURL(new Blob([byteArray.buffer], blobOpts || {}));
            this._resolveDocument(blobId, blobUrl);
        }).catch(reason => this._rejectDocument(blobId, reason));

        return documentRequest.promise;
    }

    async get(documentId, documentType = PionusDocument.raw, asBase64 = false, directLink = null) {
        if (documentId == null || documentType == null) {
            throw PionusDocumentCache.DocumentNotFoundException;
        }

        let internalDocumentIdentifier = `${documentId}.${documentType.identifier}`;

        if (this._documentData[internalDocumentIdentifier] !== undefined) {
            if (this._documentData[internalDocumentIdentifier] == null) {
                throw PionusDocumentCache.DocumentNotFoundException;
            }

            return this._documentData[internalDocumentIdentifier];
        }

        let documentRequest = this._prepareDocumentRequest(internalDocumentIdentifier);
        if (documentRequest.returnEarly) {
            return documentRequest.promise;
        }

        Logger.debug(`Fetching document ${documentId}${documentType.extension != null ? " with extension " + documentType.extension : ""} ${asBase64 ? "as base64" : ""}`);

        NetworkManager.executeFileRequest(documentId, documentType.extension, asBase64, directLink, (data, error) => {
            if (error != null) {
                this._rejectDocument(internalDocumentIdentifier, error);
            } else if (data == null || (data.length != null && data.length <= 0) || (data.byteLength != null && data.byteLength <= 0)) {
                this._rejectDocument(internalDocumentIdentifier, PionusDocumentCache.DocumentNotFoundException);
            } else {
                this._resolveDocument(internalDocumentIdentifier, data);
            }
        });

        return documentRequest.promise;
    }
}