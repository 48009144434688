import BaseView from './BaseView';

export default class PageView extends BaseView {
    constructor() {
        super($("#vbf-content"));
    }
    
    _populate() {}
    _onUserNameChanged(user) {};
    _onUserLogoChanged(user, imageUrl) {};

    subscribeToUser(user) {}
    unsubscribeFromUser() {}
}