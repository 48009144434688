function BaseFactory() {
    this._types = {};
}

BaseFactory.prototype.register = function (type, classRef) {
    this._types[type] = classRef;
};

BaseFactory.prototype.get = function (type) {
    if (this._types[type] == null) {
        Logger.warn("[BaseFactory] Attempted to instantiate object of unregistered type '" + type + "'");
        return null;
    }

    //Get all of this function's parameters minus the first one ("type")
    //This includes parameters not listed in this function's definition
    var args = Array.prototype.slice.call(arguments, 1);

    //Instantiate the class belonging to the registered type by:
    // - Retrieving the class reference from this._types
    // - Instantiating a function (class) by binding it to the class reference
    // - Applying any extra parameters passed to this function to the class constructor
    return new (Function.prototype.bind.apply(this._types[type], args));
};

export default BaseFactory;