import { EventEmitter } from '../events';
import { Codable } from '../abstract';
import { PionusDocumentCache } from '../networking';
import { PionusDocument } from '../networking/PionusDocumentCache';

export default class PionusUser extends Codable {

    static get CodingKeys() {
        return {
            email: "logonname",
            firstName: "firstname",
            fullName: "fullname",
            companyLogoId: "companylogo"
        }
    }

    emailChangedEvent = new EventEmitter(this);
    nameChangedEvent = new EventEmitter(this);
    companyLogoIdChangedEvent = new EventEmitter(this);
    companyLogoChangedEvent = new EventEmitter(this);

    constructor(email, firstName, fullName, companyLogoId) {
        super();
        
        this._email = email;
        this._firstName = firstName;
        this._fullName = fullName;
        this._companyLogoId = companyLogoId;
    }

    get email() {
        return this._email;
    }

    set email(newEmail) {
        this._email = newEmail;
        this.emailChangedEvent.notify(this.email);
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(newFirstName) {
        this._firstName = newFirstName;
        this.nameChangedEvent.notify(this.firstName, this.fullName);
    }

    get fullName() {
        return this._fullName;
    }

    set fullName(newFullName) {
        this._fullName = newFullName;
        this.nameChangedEvent.notify(this.firstName, this.fullName);
    }

    get companyLogoId() {
        return this._companyLogoId;
    }

    set companyLogoId(newCompanyLogoId) {
        if (newCompanyLogoId === 0) {
            newCompanyLogoId = null;
        }

        if (this._companyLogoId === newCompanyLogoId) {
            return;
        }

        this._companyLogoId = newCompanyLogoId;
        this.companyLogoIdChangedEvent.notify(this.companyLogoId);
        
        if (this.companyLogoId == null) {
            this.companyLogo = null;
        } else {
            PionusDocumentCache.shared.getBlob(this.companyLogoId, PionusDocument.image).then(logoUrl => {
                this.companyLogo = logoUrl;
            });
        }
    }

    get companyLogo() {
        return this._companyLogo;
    }

    set companyLogo(newCompanyLogo) {
        this._companyLogo = newCompanyLogo;
        this.companyLogoChangedEvent.notify(this.companyLogo);
    }
    
}