import BaseController from './BaseController';
import { ApplicationManager } from '../core';

export default class PageController extends BaseController {
    _manager = null;
    _view = null;

    constructor(view, manager = new ApplicationManager()) {
        super();

        this._manager = manager;
        this._view = view;
        this._view.subscribeToUser(this._manager.user);
    }
}