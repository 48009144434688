export default class LocalizationManager {
    static get shared() {
        if (this._shared == null) {
            this._shared = new LocalizationManager();
        }

        return this._shared;
    }

    get language() {
        return this._language;
    }

    get localizations() {
        return this._localizations;
    }

    constructor() {
        this._localizations = window.vbfLocalizations;
        this._language = window.vbfLanguage;
    }

    getLocalizationFor(baseString) {
        if (baseString == null || baseString.length <= 0) {
            return "ERR_KEY_NOT_SET";
        }

        let keyArr = baseString.split('.');
        let keyDepth = keyArr.length;

        let localizations = this.localizations;
        let localized = baseString;

        for (let i = 0; i < keyDepth; i++) {
            let value = keyArr[i];

            if (localizations[value] == null) {
                break;
            }

            if (localizations[value] instanceof Object) {
                localizations = localizations[value];
                continue;
            }

            if (i >= keyDepth - 1) {
                localized = localizations[value];
                break;
            } else {
                break;
            }
        }

        return localized;
    }
}

Object.defineProperty(String.prototype, "localized", {
    get: function() {
        return LocalizationManager.shared.getLocalizationFor(this);
    },

    set: function() {
        throw Error("[String.localized] This property is get-only.");
    }
});

String.prototype.fillPlaceholders = function(...args) {
    if (args.length < 1) {
        throw Error("[String.fillPlaceholders] Needs arguments!");
    }

    let string = this;

    for (let arg of args) {
        string = string.replace("%s", arg);
    }

    return string;
}

String.prototype.pluralize = function(...args) {
    if (args.length < 1) {
        throw Error("[String.pluralize] Needs arguments!");
    }

    let string = this;

    for (let i = 0; i < args.length; i++) {
        let arg = args[i];

        string = string.replace("%d", arg);
    }
};

window.vbfSetLanguage = function(locale) {
    var d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = "vbf-language=" + locale + ";" + expires + ";path=/;SameSite=Strict";

    window.location.reload();
}