import IErrorReporter from '../error-reporting/IErrorReporter';
import * as Sentry from '@sentry/browser';
import VanBeekError from './VanBeekError';

export default class SentryErrorReporter extends IErrorReporter {
    get name() {
        return "Sentry";
    }

    report(error, user) {
        if (error == null) {
            return;
        }

        let event = {
            level: 'error'
        };

        if (user != null) {
            event.user = {
                id: user.id != null ? user.id : "0",
                email: user.email,
                username: user.username
            };
        }

        if (error instanceof VanBeekError) {
            event.level = error.level;
            event.message = error.title;
            event.exception = [ { value: error.message, type: error.title } ];
            event.fingerprint = error.fingerprint;
            event.extra = error.extra;
        } else {
            event.message = error.message;
        }

        Sentry.captureEvent(event);
    }
}