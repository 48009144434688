import Logger from "js-logger";
import VanBeekError, { ErrorLevel } from "./VanBeekError";

export const NetworkErrorType = {
    NO_ERROR: 0,
    API_ERROR: 1,
    INTERNAL_ERROR: 2,
    HTTP_ERROR: 3
}

export default class NetworkError extends VanBeekError {
    jwt = null;
    responseBody = null;
    pionusMethod = null;

    _internalErrorCode = null;
    _apiErrorCode = null;
    _httpStatusCode = null;
    _headerFields = {};

    get internalErrorCode() {
        return this._internalErrorCode;
    }

    get apiErrorCode() {
        return this._apiErrorCode;
    }

    get httpStatusCode() {
        return this._httpStatusCode;
    }

    get headerFields() {
        return this._headerFields;
    }

    get fingerprint() {
        if (this.pionusMethod == null) {
            return super.fingerprint;
        }

        var fingerprint = [
            `HTTP|${this.httpStatusCode}`
        ];

        if (this.internalErrorCode != null) {
            fingerprint.push(`Internal|${this.internalErrorCode}`);
        }

        if (this.apiErrorCode != null) {
            fingerprint.push(`API|${this.apiErrorCode}`);
        }

        if (fingerprint.length > 1) {
            fingerprint.push(this.pionusMethod);
        }

        return fingerprint;
    }

    get extra() {
        var extra = {};

        if (this.jwt != null) {
            extra.jwt = this.jwt;
        }

        if (this.responseBody != null) {
            extra.responseBody = this.responseBody;
        }

        if (this.headerFields.length > 0) {
            extra.headerFields = this.headerFields;
        }

        if (window.editConfigurationId != null) {
            extra.configurationId = window.editConfigurationId;
        }

        return Object.keys(extra).length > 0 ? extra : null;
    }

    static getResponseBodyErrorType(body) {
        if (body.result != null && body.result.pionusapierrorid != null && body.result.pionusapierrorid != 0) {
            return NetworkErrorType.API_ERROR;
        }

        if (body.error != null && body.error.code != null && body.error.code != 0) {
            return NetworkErrorType.INTERNAL_ERROR;
        }

        return NetworkErrorType.NO_ERROR;
    }

    constructor(jwt, params, result, xhr) {
        super("NetworkError", null, ErrorLevel.ERROR);

        this.jwt = jwt;
        this.requestResult = result;
        this.requestParams = params;

        if (this.requestParams != null && this.requestParams.pionusmethod != null) {
            this.pionusMethod = this.requestParams.pionusmethod;
        }

        if (this.requestResult == null) {
            this.responseBody = xhr.responseText;
        }

        if (xhr != null) {
            this._headerFields = xhr.getAllResponseHeaders();
        }

        this.setMessage(xhr != null ? xhr.status : -1, xhr != null ? xhr.statusText : null);
    }

    /**
     * Extracts a more specific error summary from the constructor parameters.
     * The summery can either specify:
     * - An error returned by PionUs (e.g. incorrect request or PionUs bug)
     * - An error returned by the request itself (e.g. server down)
     * - A generic error message
     */
    setMessage(statusCode, statusText) {
        var title = "NetworkError";
        var message = `[Network | HTTP ${statusCode}`;
        var description = "";
        var internalErrorMessage = null;
        var apiErrorMessage = null;

        if (this.requestResult != null && (this.requestResult instanceof Object)) {
            var pionusInternalError = this.requestResult.error;
            if (pionusInternalError != null && pionusInternalError.code != null && pionusInternalError.code != 0) {
                title = "PionusInternalError";
                message += ` | PionUs INTERNAL ${pionusInternalError.code}`;
                this._internalErrorCode = pionusInternalError.code;

                if (pionusInternalError.message != null && pionusInternalError.message.length > 0) {
                    internalErrorMessage = pionusInternalError.message;
                }
            }

            var pionusApiData = this.requestResult.result;
            if (pionusApiData != null && pionusApiData.pionusapierrorid != null && pionusApiData.pionusapierrorid > 0) {
                title = "PionusApiError";
                message += ` | PionUs ${pionusApiData.pionusapierrorid}`;
                this._apiErrorCode = pionusApiData.pionusapierrorid;

                if (pionusApiData.pionusapierror != null && pionusApiData.pionusapierror.length > 0) {
                    apiErrorMessage = pionusApiData.pionusapierror;
                }
            }
        } else if (this.requestResult != null && this.requestResult.length > 0) {
            description += ` - ${this.requestResult}`
        }
        
        if (statusText != null && statusText !== "error" && statusText != "OK") {
            description += ` - ${statusText}`;
        }

        message += "]";

        if (statusCode === 0) {
            description += " - The request destination is unreachable or the request itself was blocked, intercepted, or otherwise interrupted.";

            this.potentialCauses = [
                "Check your internet and VPN connection.",
                "Check whether a custom DNS server is preventing internal DNS rules from being applied.",
                "Check the browser window for CORS errors.",
                "Check whether an adblocker or other request blocker is blocking the request."
            ];

            Logger.debug("Try one of the following things:");
            Logger.debug(this.potentialCauses);
        } else {
            if (this.pionusMethod != null) {
                description = ` - In ${this.pionusMethod}${description}`;
            }

            if (internalErrorMessage != null && internalErrorMessage.length > 0) {
                description += ` - ${internalErrorMessage}`;
            }

            if (apiErrorMessage != null && apiErrorMessage.length > 0) {
                description += ` - ${apiErrorMessage}`;
            }
        }

        if (description.length <= 0) {
            description = "Unknown request error";
        } else {
            description = description.substring(3);
        }

        message += ` ${description}`;

        this.title = title;
        this.message = message;
        this._httpStatusCode = statusCode;
    }
}