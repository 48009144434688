import IErrorReporter from './IErrorReporter';
import VanBeekError from './VanBeekError';
import Logger from 'js-logger';

export default class ConsoleErrorReporter extends IErrorReporter {
    get name() {
        return "Console Logging";
    }

    report(error, user) {
        if (error == null) {
            return;
        }

        var message = error.message;
        if (error instanceof VanBeekError) {
            message = error.description;
        }
        
        Logger.error(`[ConsoleErrorReporter | User ${user != null ? user.id : "unknown"}] ${message}`);
    }
}