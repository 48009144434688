/**
 * Formats a given number as a price.
 * @param   {Number} price  Price
 * @returns {String}        Formatted price
 */
export function FormatPrice(price) {
    //Accurately round price to two decimals
    var multiplicator = Math.pow(10, 4);
    price = parseFloat((price * multiplicator).toFixed(11));
    price = (Math.round(price) / multiplicator).toFixed(2);

    //Replace a whole number's decimals with a dash
    if (price.indexOf(".00") !== -1) {
        price = price.replace(".00", ".-");
    }

    //Replace the period with a comma (Dutch pricing conventions) and add periods for improved readability
    price = price.replace(".", ",");
    price = price.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return "&euro;" + price;
}

export function GetUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export function EncodeUrlId(projectId, uuid) {
    try {
        return btoa(JSON.stringify({ projectId: projectId, uuid: uuid }));
    } catch (e) {
        throw Error("[Utilities/EncodeUrlId] Encoding failed for project ID " + projectId + " and UUID " + uuid);
    }
}

export function DecodeUrlId(urlId) {
    try {
        return JSON.parse(atob(urlId));
    } catch (e) {
        throw Error("[Utilities/DecodeUrlId] Decoding failed for URL ID " + urlId);
    }
}

export function FindGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
};

var resizeTimeout;
var resizeCallbacks = [];
var _resizeThrottler = function () {
    if (!resizeTimeout) {
        resizeTimeout = setTimeout(function () {
            resizeTimeout = null;
            resizeCallbacks.forEach(function (callback) {
                callback();
            });
        }, 66);
    }
}

function OnResize(callback) {
    resizeCallbacks.push(callback);
    if (resizeCallbacks.length <= 1) {
        window.addEventListener('resize', _resizeThrottler);
    }
};
export { OnResize };

function _setMobileViewportHeightFix() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export function BindMobileViewportHeightFix() {
    OnResize(_setMobileViewportHeightFix);
    _setMobileViewportHeightFix();
};

export function IsMobileSafari() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    return iOSSafari;
};

export function SetCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "")  + expires + "; path=/; SameSite=Strict";
};

export function GetCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
};