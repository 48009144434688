function Mediator() {
	this.events = {};
	this.stalledMessages = {};
}

//Add an event to the events list
Mediator.prototype.listenTo = function (event, callback) {
	if (!this.events[event])
		this.events[event] = [];

	if (this.stalledMessages[event]) {
		for (let message; message = this.stalledMessages[event].shift();)
			message.send(callback);
	}

	this.events[event].push(callback);
};

//Remove an event from the events list
Mediator.prototype.stopListeningTo = function (event, callback) {
	let index = this.events[event].indexOf(callback);

	if (index !== -1)
		this.events[event].splice(index, 1);

	if (this.events[event].length === 0)
		delete this.events[event];
};

//Check if a event has listeners
Mediator.prototype.isAnyoneListeningTo = function (event) {
	if (!this.events[event])
		return false;

	if (this.events[event].length === 0)
		return false;

	return true;
};

//Add a stalled message to the stalledMessages list
//Try to avoid stalled messages if possible by changing module priorities
Mediator.prototype.createStalledMessage = function (event, args) {
	if (!this.stalledMessages[event])
		this.stalledMessages[event] = [];

	this.stalledMessages[event].push(new MediatorMessage(args));
};

//Send messages from the given event
Mediator.prototype.send = function (event) {
	if (!this.events[event])
		return this.createStalledMessage(event, Array.prototype.slice.call(arguments, 1));

	let returnPromise;

	for (let callback of this.events[event])
		returnPromise = callback.apply(null, Array.prototype.slice.call(arguments, 1));

	return returnPromise;
};

function MediatorMessage(args) {
	this.arguments = args;
}

MediatorMessage.prototype.send = function (callback) {
	callback.apply(null, this.arguments);
};

export default new Mediator();