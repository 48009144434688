import { NotImplementedError } from '../core/InterfaceHelper';

export default class IErrorReporter {
    get name() {
        throw new NotImplementedError();
    }

    report(error, user) {
        throw new NotImplementedError();
    }
}