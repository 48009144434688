export const ErrorLevel = {
    DEBUG:  "debug",
    INFO:   "info",
    WARN:   "warn",
    ERROR:  "error",
    FATAL:  "fatal"
}

export default class VanBeekError extends Error {
    title = "";
    message = "";
    level = ErrorLevel.ERROR;

    get fingerprint() {
        return ["{{ default }}"];
    }

    get extra() {
        return null;
    }

    get description() {
        return `[${this.level.toUpperCase()}] ${this.title}: ${this.message}`;
    }

    constructor(title = "Unknown error", message = "An unknown error occurred", level = ErrorLevel.ERROR) {
        super();

        this.title = title;
        this.message = message;
        this.level = level;
    }
}