export default class EventSubscription {
    _eventEmitter = null;
    _callback = null;

    get eventEmitter() {
        return this._eventEmitter;
    }

    get callback() {
        return this._callback;
    }

    constructor(eventEmitter, callback) {
        this._eventEmitter = eventEmitter;
        this._callback = callback;
    }

    unsubscribe() {
        this.eventEmitter.unsubscribe(this);
    }

    destroy() {
        this._callback = null;
        this._eventEmitter = null;
    }
}