import { PionusUser } from '../entities';
import Logger from 'js-logger';

export default class ApplicationManager {

    static _LocalStorageUserEmailKey = "vbf-user-email";

    constructor() {
        this.user = PionusUser.Decode(window.pionusUserInfo);

        var localStorageUserEmail = localStorage.getItem(this.constructor._LocalStorageUserEmailKey);
        if (this.user.email != localStorageUserEmail) {
            Logger.info("[ApplicationManager] localStorage user differs from PHP user. Clearing localStorage data.");
            localStorage.clear();
            localStorage.setItem(this.constructor._LocalStorageUserEmailKey, this.user.email);
        }
    }

}