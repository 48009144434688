import '../../css/common.css';

//START ugly global imports legacy stuff
import $ from 'jquery';
import Logger from 'js-logger';
import KJUR from 'jsrsasign';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

window.$ = $;
window.Logger = Logger;
window.KJUR = KJUR;
window.Sentry = Sentry;
//END ugly global imports legacy stuff

import { NetworkManager } from '../networking';
import ErrorReportingService, { ErrorReportingUser } from '../error-reporting/ErrorReportingService';
import SentryErrorReporter from '../error-reporting/SentryErrorReporter';
import './LocalizationManager';

function ApplicationBootstrapper() { }

ApplicationBootstrapper.DEFAULT_APP_VERSION = "0.0.0";

ApplicationBootstrapper.prototype.bootstrap = function (config, callback) {
    this.config = config;

    if (callback == null) {
        callback = function () { };
    }

    if (config != null && config.application != null) {
        window.DEBUG_MODE = config.application.debugMode;
        if (window.APPLICATION_VERSION == null) {
            window.APPLICATION_VERSION = config.application.version != null ? config.application.version : ApplicationBootstrapper.DEFAULT_APP_VERSION;
        }

        if (window.APPLICATION_NAME == null) {
            window.APPLICATION_NAME = config.application.name != null ? config.application.name : "Unknown";
        }
    } else {
        window.DEBUG_MODE = true;
        if (window.APPLICATION_VERSION == null) {
            window.APPLICATION_VERSION = ApplicationBootstrapper.DEFAULT_APP_VERSION;
        }

        if (window.APPLICATION_NAME == null) {
            window.APPLICATION_NAME = "Unknown";
        }
    }

    NetworkManager.setDebugMode(window.DEBUG_MODE);

    this._initializeLogger();
    Logger.info(`Hello developer! You are developing ${APPLICATION_NAME} v${APPLICATION_VERSION}`);

    if (DEBUG_MODE !== true) {
        console.warn("%cSTOP!", "color: red; font-size: 48px; font-weight: bold;");
        console.warn("%cThis is a browser feature intended for developers. Unless you know what you're doing you should *never* type anything in here.", "color: red; font-size: 18px; font-weight: bold;");
        console.log("%cIf you do know what you're doing you should go to https://www.van-beek.nl/contact and apply for a job, you handsome devil ;)", "color: green; font-size: 18px");
        console.log("%c ", "font-size: 48px;");
    }

    if (window.clearSessionData === true) {
        Logger.debug("[ApplicationBootstrapper] Cleared localStorage");
        localStorage.clear();
    }

    if (window.pionusUsername != null) {
        ErrorReportingService.shared.user = new ErrorReportingUser(window.pionusUsername);
    }

    if (config == null) {
        return;
    }

    if (config.sentry != null) {
        this._initializeSentry(config.sentry, callback);
    } else {
        Logger.warn("[ApplicationBootstrap] Sentry configuration not found! Sentry will be disabled for this session");
        callback();
    }
};

ApplicationBootstrapper.prototype._initializeLogger = function () {
    Logger.useDefaults({
        defaultLevel: Logger.DEBUG
    });

    var consoleHandler = Logger.createDefaultHandler({
        formatter: function (messages, context) {
            messages.unshift(new Date().toUTCString() + " -");
        }
    });

    var sentryHandler = function (messages, context) {
        if (window.Sentry == null) {
            return;
        }

        Sentry.addBreadcrumb({
            category: 'Logger',
            message: messages[0],
            level: context.level
        });
    };

    Logger.setHandler(function (messages, context) {
        //Don't manually handle with Sentry unless the message is not printed to console.
        //This is because Sentry automatically adds console logs to the breadcrumbs, which would cause duplicate messages.
        if (window.DEBUG_MODE || context.level.value >= Logger.WARN.value) {
            consoleHandler(messages, context);
        } else {
            sentryHandler(messages, context);
        }
    });
};

ApplicationBootstrapper.prototype._initializeSentry = function (config, callback) {
    //Don't initialize Sentry if debug mode is enabled
    if (config.enabled != null && ((!DEBUG_MODE && config.enabled >= 0) || config.enabled > 0)) {
        Sentry.init({
            dsn: config.dsn,
            release: window.APPLICATION_VERSION,
            integrations: [new Integrations.ExtraErrorData()]
        });

        if (window.pionusUsername != null) {
            Sentry.configureScope((scope) => {
                scope.setUser({ "email": window.pionusUsername });
            });
        }

        ErrorReportingService.shared.reporter = new SentryErrorReporter();

        Logger.info("Sentry initialized with DSN: " + config.dsn);
        callback();
    } else {
        let sentryStatusMessage = config.enabled < 0 ? "Force disabled by config (-1)" : config.enabled === 0 ? "Disabled whilst debug mode is enabled (0)" : "Enabled not specified in config (null)";
        Logger.warn(`Sentry has been disabled for this session: ${sentryStatusMessage}`);
        callback();
    }
};

export default ApplicationBootstrapper;