
import ErrorReportingService from '../error-reporting/ErrorReportingService';
import NetworkError, { NetworkErrorType } from '../error-reporting/NetworkError';
import { LocalizationManager } from '../core';
import '../../vendors/javascript/jquery/jquery-ajax-blob-arraybuffer';

function NetworkManager() {
    this._requestCallbacks = [];
    this.setDebugMode(false);
}

NetworkManager.prototype.setDebugMode = function(debugMode) {
    NetworkManager.API_URL = debugMode === true ? "https://dev.api.pionus.van-beek.nl/" : "https://api.pionus.van-beek.nl/";
    NetworkManager.API_FILES_URL = NetworkManager.API_URL + "files/";
};

/**
 * Returns properly formatted parameters for a PionUs request.
 * @param   {String} method       PionUs method to call
 * @param   {Object} pionusParams Parameters to pass to the PionUs method
 * @param   {Object} extraParams  Parameters to pass to PionUs directly
 * @returns {Object}              Properly formatted JSON object for a PionUs request
 */
NetworkManager.prototype.getParameters = function (method, pionusParams, extraParams) {
    var username = this.getUsername();
    if (username == null) username = "";

    var params = {
        pionusapiversion: "1",
        pionusmethod: method,
        username: username,
        languagecode: LocalizationManager.shared.language,
        pionusparameters: pionusParams != null ? pionusParams : []
    }

    for (var paramKey in extraParams) {
        params[paramKey] = extraParams[paramKey];
    }

    return params;
};

/**
 * Builds a JWT string based on the given parameters.
 * @param   {Object} params JSON object representing the parameters to send to PionUs
 * @returns {String}        JWT string
 */
NetworkManager.prototype.buildJWT = function (params) {
    // Header
    var jwtHeader = {
        alg: 'HS256',
        typ: 'JWT'
    };

    // Payload
    var jwtPayload = {
        exp: KJUR.jws.IntDate.get('now + 1month'),
        jsonrpc: "2.0",
        method: "pionusmethod",
        id: "1",
        params: params
    };

    var secret = window.pionusJwtToken;
    if (secret == null || secret.length <= 0) {
        throw Error("[NetworkManager] No secret was provided to the JavaScript code!");
    }

    var jwt = KJUR.jws.JWS.sign(
        "HS256",
        JSON.stringify(jwtHeader),
        JSON.stringify(jwtPayload),
        { rstr: secret }
    );

    return jwt;
};

NetworkManager.prototype.executeFileRequest = function (documentId, extension, base64, directLink, callback) {
    let params = [];
    if (extension != null) {
        params.push(`extension=${extension}`);
    }

    if (base64 === true) {
        params.push(`base64`);
    }

    if (directLink != null) {
        params.push(`directlink=${directLink}`)
    }

    return $.get(`/documents/${documentId}?${params.join("&")}`).then(data => {
        callback(data);
    }).catch(error => {
        callback(null, error);
    });
};

NetworkManager.prototype.onRequest = function(callback) {
    if (!(callback instanceof Function)) {
        return;
    }

    this._requestCallbacks.push(callback);
};

NetworkManager.prototype.executeRequest = function (params, callback) {
    return this._sendRequest(NetworkManager.API_URL, params, "json", callback);
};

NetworkManager.prototype.executeGenericRequest = function(url = NetworkManager.API_URL, params, dataType, callback) {
    return this._sendRequest(url, params, dataType, callback);
};

/**
 * Sends a request to PionUs.
 * @param   {String}   url        API url to send the request to
 * @param   {Object}   params     Parameters to send in the request body
 * @param   {Function} callback   Callback to call with request results
 * @returns {Object}              The AJAX request
 */
NetworkManager.prototype._sendRequest = function (url, params, dataType, callback) {
    var jwt = this.buildJWT(params);
    var timerKey;

    if (DEBUG_MODE) {
        timerKey = Date.now() + " - " + jwt;
        Logger.time(timerKey);
    }

    if (callback == null)
        callback = function () { };

    for (let callback of this._requestCallbacks) {
        callback(url, jwt);
    }

    return $.ajax({
        url: url,
        method: "POST",
        data: jwt,
        crossDomain: true,
        xhrFields: { withCredentials: true },
        dataType: dataType === "json" ? "json" : undefined
    })

    .done((data, textStatus, xhr) => {
        if (DEBUG_MODE) {
            Logger.timeEnd(timerKey);
        }

        let errorType = NetworkError.getResponseBodyErrorType(data);
        if (errorType != NetworkErrorType.NO_ERROR) {
            this.handleError(jwt, params, data, textStatus, xhr, callback);
            return;
        }

        if (data == null || data.result == null) {
            callback(data, null);
        } else if (data.result.pionusapicallresult == null) {
            callback(data.result, null);
        } else {
            callback(data.result.pionusapicallresult, null);
        }
    })

    .fail((xhr, textStatus, error) => {
        if (DEBUG_MODE) {
            Logger.timeEnd(timerKey);
        }

        this.handleError(jwt, params, error, textStatus, xhr, callback);
    });
};

/**
 * Handles the failed completion of an ajax request.
 * @param {String} jwt      Request JSON Web Token
 * @param {Object} params   Request parameters
 * @param {Object} error    Returned error
 * @param {String} status   Request status info
 */
NetworkManager.prototype.handleError = function (jwt, params, error, status, xhr, callback) {
    //Ignore aborted requests (intended)
    if (status === 'abort') {
        return;
    }

    var networkError = new NetworkError(jwt, params, error, xhr);
    ErrorReportingService.shared.report(networkError);

    callback(null, networkError);
    Logger.warn(networkError.message);
};

NetworkManager.prototype.getUsername = function () {
    return window.pionusUsername;
};

NetworkManager.prototype.getToken = function () {
    return window.pionusJwtToken;
};

export default new NetworkManager();
