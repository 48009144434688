function Observable() {
    this.observers = {};
}

Observable.prototype.addObserver = function (key, observer) {
    if (this.observers[key] == null) {
        this.observers[key] = [];
    }

    if (this.observers[key].indexOf(observer) !== -1) {
        Logger.warn("[Observable] Attempted to add an observer which already exists in the list of observers.");
        return;
    }

    this.observers[key].push(observer);
};

Observable.prototype.removeObserver = function (key, observer) {
    if (this.observers[key] == null) {
        return;
    }

    var index = this.observers[key].indexOf(observer);
    if (index === -1) {
        Logger.warn("[Observable] Attempted to remove an observer which does not exist in the list of observers.");
        return;
    }

    this.observers[key].splice(index, 1);
};

Observable.prototype.notifyObservers = function (key, obj) {
    if (this.observers[key] == null) {
        return;
    }

    this.observers[key].forEach(function (observer) {
        observer.update(key, obj);
    });
};

export default Observable;