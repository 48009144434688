import Logger from 'js-logger';
import ConsoleErrorReporter from './ConsoleErrorReporter';

export class ErrorReportingUser {
    id = null;
    email = null;
    username = null;

    constructor(id = null, email = null, username = null) {
        this.id = id;
        this.email = email;
        this.username = username;
    }
}

export default class ErrorReportingService {
    static _shared = null;
    static get shared() {
        if (this._shared == null) {
            this._shared = new ErrorReportingService();
        }

        return this._shared;
    }

    _reporter = null;
    get reporter() {
        return this._reporter;
    }

    set reporter(newValue) {
        this._reporter = newValue;
        if (this.reporter != null) {
            Logger.debug(`[ErrorReportingService] Reporter set to ${this.reporter.name}`);
        }
    }

    user = null;

    constructor(reporter = null, user = null) {
        this.reporter = reporter != null ? reporter : new ConsoleErrorReporter();
        this.user = user;
    }

    report(error) {
        if (this.reporter == null) {
            Logger.warn("[ErrorReportingService] Reporter not set. The following error was not reported:");
            Logger.warn(error.message);
            return;
        }

        this.reporter.report(error, this.user);
    }
}