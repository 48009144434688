import { EventEmitter } from '../events';

export default class Codable {

    decodeFinishedEvent = new EventEmitter(this);

    /*static RedefinedProperties = {};
    static RedefinedSetter = function(originalSet, key, newValue) {
        console.log(this);
        originalSet.call(this, newValue);
        
        console.log("Set")
        if (!this.isDecoding) {
            console.log("CODABLE DATA CHANGED!");
            this.codableDataChangedEvent.notify(key, newValue);
        }
    }

    codableDataChangedEvent = new EventEmitter(this);
    isDecoding = false;

    constructor() {
        var codingKeys = this.constructor.CodingKeys;

        if (Codable.RedefinedProperties[this.constructor.name] == null) {
            var redefinedProperties = {};
            
            for (var key in codingKeys) {
                var propertyDescriptor = Reflect.getOwnPropertyDescriptor(this.constructor.prototype, key);
                var originalSet = propertyDescriptor.set;
 
                propertyDescriptor.set = function(newValue) {
                    return Codable.RedefinedSetter.call(this, originalSet, key, newValue);
                };

                redefinedProperties[key] = propertyDescriptor;
            }

            Codable.RedefinedProperties[this.constructor.name] = redefinedProperties;
        }

        

        for (key in codingKeys) {
            Object.defineProperty(
                this.constructor.prototype,
                key, Codable.RedefinedProperties[this.constructor.name][key]
            );
        }
    }*/

    static get CodingKeys() {
        throw Error("CodingKeys are not implemented by this class");
    }

    /* TODO: This is under construction */
    static DecodeProperties(json) {
        var properties = {};
        var codingKeys = this.CodingKeys;

        for (var key in codingKeys) {
            if (key == null || codingKeys[key] == null) {
                throw Error("Invalid coding key or value: " + key + " - " + value);
            }

            var result = json[codingKeys[key]];
            if (result === undefined) {
                result = null;
            }

            if (codingKeys[key] instanceof Array && codingKeys[key].length >= 1) {
                var arrayDescription = codingKeys[key][0];
                var arrayProperty = arrayDescription.property;

                result = [];

                if (json[arrayProperty] instanceof Array) {
                    for (var value of json[arrayProperty]) {
                        if (arrayDescription.class != null) {
                            value = arrayDescription.class.Decode(value);
                        }

                        result.push(value);
                    }
                } else {
                    continue; //Skip update because array data is missing from JSON
                }
            } else if (codingKeys[key].class != null && json[codingKeys[key].property] != null) {
                if (objInstance[key] != null && objInstance[key].decode != null) {
                    objInstance[key].decode(json[codingKeys[key].property]);
                    result = objInstance[key];
                } else {
                    result = codingKeys[key].class.Decode(json[codingKeys[key].property]);
                }
            }

            properties[key] = result;
        }

        return properties;
    }

    dataEquals(json) {
        return false;
    }

    static Decode(json, objInstance) {
        if (objInstance == null) {
            objInstance = new this();
        }

        objInstance.isDecoding = true;

        var codingKeys = this.CodingKeys;

        for (var key in codingKeys) {
            if (key == null || codingKeys[key] == null) {
                throw Error("Invalid coding key or value: " + key + " - " + value);
            }

            var result = json[codingKeys[key]];
            if (result === undefined) {
                result = null;
            }

            if (codingKeys[key] instanceof Array && codingKeys[key].length >= 1) {
                var arrayDescription = codingKeys[key][0];
                var arrayProperty = arrayDescription.property;

                result = [];

                if (json[arrayProperty] instanceof Array) {
                    for (var value of json[arrayProperty]) {
                        if (arrayDescription.class != null) {

                            var originalUpdated = false;

                            if (objInstance[key] instanceof Array) {
                                for (var item of objInstance[key]) {
                                    if (item.dataEquals(value)) {
                                        item.decode(value);
                                        value = item;
                                        originalUpdated = true;
                                        break;
                                    }
                                }
                            }

                            if (!originalUpdated) {
                                value = arrayDescription.class.Decode(value);
                            }
                        }

                        result.push(value);
                    }
                } else if (json[arrayProperty] === undefined) {
                    continue; //Skip update because array data is missing from JSON
                }
            } else if (codingKeys[key].class != null && json[codingKeys[key].property] != null) {
                if (objInstance[key] != null && objInstance[key].decode != null) {
                    objInstance[key].decode(json[codingKeys[key].property]);
                    result = objInstance[key];
                } else {
                    result = codingKeys[key].class.Decode(json[codingKeys[key].property]);
                }
            }

            objInstance[key] = result;
        }

        objInstance.isDecoding = false;
        objInstance.decodeFinishedEvent.notify();
        
        return objInstance;
    }

    static Encode(obj) {
        var codingKeys = this.CodingKeys;
        var json = {};

        for (var key in codingKeys) {
            if (key == null || codingKeys[key] == null) {
                throw Error("Invalid coding key or value: " + key + " - " + value);
            }

            var result = obj[key];
            var jsonKey = codingKeys[key];

            if (result === undefined) {
                result = null;
            }

            if (codingKeys[key] instanceof Array && codingKeys[key].length >= 1 && obj[key] != null) {
                var arrayDescription = codingKeys[key][0];
                jsonKey = arrayDescription.property;

                result = [];

                for (var value of obj[key]) {
                    if (arrayDescription.class != null) {
                        value = arrayDescription.class.Encode(value);
                    }

                    result.push(value);
                }
            } else if (codingKeys[key].class != null && obj[key] != null) {
                result = obj[key].encode();
                jsonKey = codingKeys[key].property;
            }

            json[jsonKey] = result;
        }

        return json;
    }

    static ReadEncodedProperty(json, propertyName) {
        if (this.CodingKeys[propertyName] != null) {
            if (this.CodingKeys[propertyName].class != null) {
                return this.CodingKeys[propertyName].class.Decode(json[this.CodingKeys[propertyName]].property);
            }

            return json[this.CodingKeys[propertyName]];
        }

        return null;
    }

    encode() {
        return this.constructor.Encode(this);
    }

    decode(json) {
        this.constructor.Decode(json, this);
    }
}