import EventSubscription from './EventSubscription';

export default class EventEmitter {
    _sender = null;
    _onSubscribe = null;
    _subscribers = [];

    constructor(sender, onSubscribe) {
        this._sender = sender;
        this._onSubscribe = onSubscribe || function () { };
    }

    subscribe(subscriber) {
        var subscription = new EventSubscription(this, subscriber);
        this._subscribers.push(subscription);
        this._onSubscribe(subscription);
        return subscription;
    }

    unsubscribe(subscription) {
        var index = this._indexOf(subscription);
        if (index < 0) {
            console.warn("Unsubscribing failed because subscriber was not found")
            return false;
        }

        this._subscribers.splice(index, 1);
        subscription.destroy();

        return true;
    }

    notify(...args) {
        if (!(args instanceof Array)) {
            args = [];
        }

        args.splice(0, 0, this._sender);

        for (var subscription of this._subscribers) {
            subscription.callback.apply(null, args);
        }
    }

    _indexOf(subscription) {
        return this._subscribers.indexOf(subscription);
    }
}