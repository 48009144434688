export default class BaseView {
    _elem = null;

    get $elem() {
        return this._elem;
    }

    constructor(elem) {
        this._elem = elem;
        this._populate();
        this._setupEvents();
    }

    _populate() { }
    _setupEvents() { }
    subscribeTo(model) { }
    unsubscribeFrom(model) { }
}